import React from 'react';
import './styles.scss';
import Directory from '../../components/Directory';

const Homepage = props => {
  return (
    <section className="homepage">
      <Directory />
    </section>
  )
};

export default Homepage;