import React from "react";

import './styles.scss'

// core components
import AboutPageHeader from '../../components/AboutPageHeader';

function About() {

  return (
    <div className="fill-page">
      <AboutPageHeader />
    </div>
  );
}

export default About;
