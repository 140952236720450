import React from 'react';
import PostCard from './../../components/PostCard';

const PostDetails = () => {
  return (
    <div>
      <PostCard />
    </div>
  );
}

export default PostDetails;