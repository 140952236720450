import React from 'react';
import Contact from '../../components/Contact/index';
import './styles.scss'

class ContactPage extends React.Component {

  render() {
    return(
      <div>
        <Contact  />
      </div>
    )
  }
}
export default ContactPage
