import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { checkUserSession } from './redux/User/user.actions';
import loadGif from './assets/images/logos/OTR.gif'

// components
import AdminToolbar from './components/AdminToolbar';

// hoc
import WithAuth from './hoc/withAuth';
import WithAdminAuth from './hoc/withAdminAuth';

// layouts
import MainLayout from './layouts/MainLayout';
import HomepageLayout from './layouts/HomepageLayout';
import Blog from './pages/Blog';
import AdminLayout from './layouts/AdminLayout';
import DashboardLayout from './layouts/DashboardLayout';

// pages
import Homepage from './pages/Homepage';
import Registration from './pages/Registration';
import Login from './pages/Login';
import Recovery from './pages/Recovery';
import Dashboard from './pages/Dashboard';
import Admin from './pages/Admin';
import PostDetails from './pages/PostDetails';
// import ProductDetails from './pages/ProductDetails';
// import Cart from './pages/Cart';
// import Payment from './pages/Payment';
// import Order from './pages/Order';
// import Search from './pages/Search';
import './default.scss';

//notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//artist specific imports
import About from '../src/pages/About'
import Catalog from './pages/Catalog';
import Footer from './components/Footer';
import ContactPage from './pages/ContactPage';



const App = props => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    dispatch(checkUserSession());

  }, []);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 4000);
  }, [4000]);

  return (
    visible ?
      <div className="loading-gif-container">
        <img className="loading-gif" src={loadGif} alt="loading-gif"/>
      </div>
      :
    <div className="App">
      <div className="full-height">
        <ToastContainer />
        <Switch>
          <Route exact path="/" render={() => (
            <HomepageLayout>
              <Homepage />
            </HomepageLayout>
          )} />
          <Route path="/about" render={() => (
             <MainLayout>
               <About />
             </MainLayout>
           )}/>
          <Route path="/catalog" render={() => (
             <MainLayout>
               <Catalog />
             </MainLayout>
           )}/>
          <Route exact path="/blog" render={() => (
            <WithAuth>
              <MainLayout>
                <Blog/>
              </MainLayout>
            </WithAuth>
          )} />
          <Route path="/blog/:filterType" render={() => (
            <WithAuth>
              <MainLayout>
                <Blog />
              </MainLayout>
            </WithAuth>
          )} />
          <Route path="/post/:postID" render={() => (
            <MainLayout>
              <PostDetails />
            </MainLayout>
          )} />
          <Route path="/registration" render={() => (
            <MainLayout>
              <Registration />
            </MainLayout>
          )} />
          <Route path="/login"
             render={() => (
               <MainLayout>
                 <Login />
               </MainLayout>
             )} />
          <Route path="/recovery" render={() => (
            <MainLayout>
              <Recovery />
            </MainLayout>
          )} />
          <Route path="/dashboard" render={() => (
            <WithAuth>
              <DashboardLayout>
                <Dashboard />
              </DashboardLayout>
            </WithAuth>
          )} />
          <Route path="/admin" render={() => (
            <WithAdminAuth>
              <AdminLayout>
                <Admin />
              </AdminLayout>
            </WithAdminAuth>
          )} />
          <Route path="/contact" render={() => (
            <MainLayout>
              <ContactPage />
            </MainLayout>
          )} />
        </Switch>
        <Footer />
        <AdminToolbar />
      </div>
    </div>
  );
}

export default App;