import React from 'react';

const Dashboard = props => {
  return (
    <h1>
      Welcome to your account
    </h1>
  )
}

export default Dashboard;