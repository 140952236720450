import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import Background from '../../assets/images/directory/about-roman.png';

// core components

function AboutPageHeader() {
  let pageHeader = React.createRef();
  const futureFunk = 'https://www.theprospectordaily.com/2019/11/12/marching-band-student-and-classical-composer-explains-future-funk/';
  const house = 'https://en.wikipedia.org/wiki/House_music';
  const daftPunk = 'https://en.wikipedia.org/wiki/Daft_Punk';
  const fredFalke = 'https://en.wikipedia.org/wiki/Fred_Falke';
  const bob = 'https://en.wikipedia.org/wiki/Bob_Sinclar';
  const MOJO = 'https://en.wikipedia.org/wiki/Mojo_(magazine)';
  const boogie = 'https://soundcloud.com/rmnrmn/lets-boogie-get-up'
  const withYou = 'https://soundcloud.com/rmnrmn/when-im-with-you'
  const sailor = 'https://soundcloud.com/rmnrmn/sailor-bae'
  const styles = {
    pageHeader: {
      backgroundImage: `url(${Background})`
    },
    setWidth: {
      width: '75%'
    }
  }

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={styles.pageHeader}
      >
        <div className="filter" />
        <div className="content-center">
          <Container >
            <Container className="about-container" styles={styles.setWidth}>
              <h1 id="about-header" className="antro_vectra">
                Funk is the game
              </h1>
              <br/>
              <p className="white-text">
                ROMAN is a <a href={futureFunk} target="_blank" rel="noreferrer">future funk</a> and
                 <a href={house} target="_blank" rel="noreferrer"> house</a> music artist based out of Sacramento,
                California. ROMAN has been producing music for over 10 years and has
                had numerous projects. He has a unique sample selection
                and uses creative loops to compose grooves that are guaranteed to get you moving.
                ROMAN finds inspirations in: <a href={daftPunk} target="_blank" rel="noreferrer"> Daft Punk</a>,
                <a href={fredFalke} target="_blank" rel="noreferrer"> Fred Falke</a>, <a href={bob} target="_blank" rel="noreferrer"> Bob Sinclaire</a>, and <a href={MOJO} target="_blank" rel="noreferrer"> MOJO</a>. This inspiration has brought tracks such as
                <a href={boogie} target="_blank" rel="noreferrer"> "Let's Boogie (Get Up!)</a>, <a href={withYou} target="_blank" rel="noreferrer"> When I'm With You</a>, and <a href={sailor} target="_blank" rel="noreferrer"> Sailor Bae</a>.
              </p>
              <br/>
              <p>
                ROMAN is the president and founder of <a>On The Rocks</a>, a Sacramento California record label that specializes in growing and highlighting
                local Sacramento artists and producing physical record albums. He is growing his brand by signing the sickest
                grooves in California and hopes to open a bar / venue in downtown Sacramento.
              </p>
              {/*<FullBackgroundCard />*/}
            </Container>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutPageHeader;