import React from "react";
import PostResults from "../../components/PostResults";

const Blog = () => {
  return (
    <div className="blogPage">
      <PostResults />
    </div>
  );
};

export default Blog;
