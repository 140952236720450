import React from 'react';
import './styles.scss';
import {Container, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import Logo from '../../assets/images/logos/OTRR_Cup_Icon.png'

const Footer = props => {
  return (
    <footer className="footer footer-gray footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a
                  // change this later
                  href="http://masonmeier.info"
                  target="_blank" rel="noreferrer"
                  className="mr-1"
                >
                  On The Rocks
                </a>
              </li>
              <li>
                <Link to='/blog'>
                  Blog
                </Link>

              </li>
              <li>
                <a
                  href="https://app.termly.io/document/privacy-policy/49d98918-8e8f-42e5-bfe6-dc4ee8abbca5"
                  target="_blank" rel="noreferrer"
                >
                  Privacy Notice
                </a>
              </li>
              <li className="footer-contain">
                <img src={Logo} alt="footer logo" className="footer-logo" />
              </li>
              <li>
                © {new Date().getFullYear()}
                , made with <i className="fa fa-heart heart" /> by <a className="dev-name" href="http://masonmeier.info">Mason Meier</a>
              </li>
            </ul>
          </nav>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;